<template>
    <div>
        <div
            v-if="picture"
        >
          <img
            :src="picture.image"
            style="width:100%"
          />
          {{ picture.file.name }}
          <div
            v-for="entry of exif"
            :key="entry[0]"
          >
            {{ entry[0] }} : {{ entry[1] }}
          </div>
        </div>
    </div>
</template>

<script>
export default {
  computed: {
    picture () {
      const id = this.$route.params.id
      const picture = this.$store.getters.pictureById(id)
      return picture
    },
    exif () {
      return Object.entries(this.picture.exif)
    }
  }
}
</script>